.am-splash {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.4)), url('./../../assets/images/am-splash.png');
  background-repeat: no-repeat;
  background-size: 225px 225px;
  background-position: right 0 bottom 0;
}

@media screen and (min-width: 30em) {
  .am-splash {
    background-image: none;
  }
}