* {
	outline: 0 !important;
}

html, body {
	height: 100%;
}

#root {
	height: 100%;
}