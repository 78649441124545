/* HIDE RADIO */
[type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio] + img {
  cursor: pointer;
}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 2px solid #f6ad55  !important;
}

.img-radio-parent .chakra-radio {
  height: 72px;
  width: 72px;
}
// to hide the radio
.img-radio {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.img-radio + span.chakra-radio__label {
  cursor: pointer;
}

.img-radio-parent input:checked + span + .chakra-radio__label  {
  outline: 2px solid #f6ad55  !important;
}